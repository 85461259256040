//Global
body {
	background-color: transparent;
	font-family: $font-family-sans-serif;
	> #vue-app,
	> #vue-error {
		background-color: transparent;
	}
}
.page-content {
    background-color: $white;
}
/* #page-body {
	margin-top: 2rem;
} */
//Global End

//Homepage Start
.home {
	.hero-main {
		.carousel {
			.carousel-indicators {
				bottom: 0;
				top: auto;
				li {
					border-color: $brand-primary;
					box-sizing: border-box;
					border-radius: .8rem;
					width: .7rem;
					height: .7rem;
					margin: 2px;
					border: 1px solid $brand-primary;
					&.active {
						background:$brand-primary;
					}
				}
			}
			.carousel-control {
				position: absolute;
				bottom: 0;
				span {
					font-size: 60px;
					bottom: 10%;
					top: auto;
					&:before {
						color: $brand-primary;
					}
				}
			}
			.left.carousel-control {
				padding: 2rem;
				left: 0;
			}
			.right.carousel-control {
				right: 0;
				padding: 2rem;
			}
		}
	}
}

.list-item-carousel {
	.owl-item {
    		list-style-type: none;
	}
}
.no-carousel {
	.col-12 {
	      list-style-type: none;
      }
}


//Homepage End

//Header Start
#page-header {
	background-color: transparent !important;
	&.top-bar {
		background-color: $top-bar-bg !important;
	}
	.navbar {
		background-color: transparent;
		background: $brand-primary;
		.container-max {
			font-family: $font-family-heading;
		}
	}
}

.section-header.mb-3 {
	background: transparent;
	padding: 0;
	h4 {
		color: $black;
	}
}


#mainNavbarCollapsable {
	ul.mainmenu {
		>li {
			>a {
				font-family: $font-family-heading;
				color: white;
			}
		}
	}
}
//Header End

//Footer Start
.footer{
	display: block;
	background-color: $footer-bg;
	max-width:1200px;
	margin:0 auto;
	>.container-max {
		background-color:$footer-bg;
	}

	a{
		color: $footer-color;
	}

	.footer-features{
		border-bottom: 1px solid $footer-features-color;
		margin: 0 auto;
		padding-bottom: 4rem !important;
		background-color: $footer-features-bg;
		color: $footer-features-color;
		i {
			color:$footer-features-color !important;
		}
	}

	.footer-content{

	}

	.footer-services{
		display: flex;
		flex-flow: column nowrap;

		.services-payment,
		.services-shipping,
		.services-certificate{
			display: inline-block;
			display: inline-flex;
			flex-flow: row wrap;
			flex: 0 1 auto;
		}

		.services-certificate{
			flex: 1 0 auto;
		}

		.services-title{
			width: 100%;
		}

		a{
			flex: 1 1 auto;
			display: inline-block;
			max-width: 5rem;
    		margin-right: .5rem;
		}

		img{
			max-height: 4rem;
		}
	}

	.footer-column{

		.h5{
			font-weight: 700;
			color: $white;
		}
		ul {
			li{
				a {
					color:$white;
				}
			}
		}
	}

	.newsletter{

	}

	.btn-mail {
	    background-color: $gray;
	    color: white;

		i {
	    	padding: 0 1rem;
		}

		&:hover {
			background-color: $gray-light;
		}
	}

	.copyright {
		small {
			color:$white;
		}
	}

}
//Footer End

//Category Start
.categoriegrid {
	padding: 1rem !important;
}
.parallax-img-container {
	.parallax-text-container {
		h1 {
			margin-bottom: 0;
			word-wrap: break-word;
			color: $brand-primary;
		}
	}
}
.anicon {
	>.anicon-search-item {
		&::after {
			background: $top-bar-bg;
		}
	}
}

.wrapper-main {
	.breadcrumbs {
		background: transparent;
	}
	>nav.container-max {
		background: transparent;
	}
}
.breadcrumb-item.active {
	color: $gray-light;
}


.list-controls {
	position:relative;
	.list-controls-inner {
		background: $gray-lighter;
		padding:.5em;
	}
}
//Category End

//Single Start
.single {
	background-color: $white;
	padding: 1rem;
}
//Single end

.cmp-product-thumb {
	border: 1px solid $gray-light;
	.thumb-title {
		text-align: center;
	}
	.prices {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.vat.small {
	    	text-align: center;
	    	display: block;
	}
}
